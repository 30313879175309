<template>
  <OpenInApp />
</template>

<script setup lang="ts">
  const { loadingDialogInstance } = useLoadingDialog()
  onMounted(() => {
    loadingDialogInstance.close()
  })
</script>
